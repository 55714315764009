import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto nacl box keypair`}</strong>{` -- generate a key for use with seal and open`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto nacl box keypair <pub-file> <priv-file>
`}</code></pre>
    <h2>{`Description`}</h2>
    <p>{`Generates a new public/private keypair suitable for use with seal and open.
The private key is encrypted using a password in a nacl secretbox.`}</p>
    <p>{`This command uses an implementation of NaCl's crypto_box_keypair function.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto nacl box`}</strong>{`.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`pub-file`}</inlineCode>{`
The path to write the public key.`}</p>
    <p><inlineCode parentName="p">{`priv-file`}</inlineCode>{`
The path to write the encrypted private key.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      